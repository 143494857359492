import { AppRouter } from './routes';

function App() {

  return (
    <div className={`App`}>
          <AppRouter />
    </div>
  );
}

export default App;
