import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '../i18n';
import logo from '../Assets/Hani Logo.svg';
import insta from '../Assets/Icons/instagrem.svg';
import linkedin from '../Assets/Icons/Linkedin.svg';
import twitter from '../Assets/Icons/Twitter.svg';
import { config } from '../config/index';
import location from '../Assets/Icons/location.svg';
import mail from '../Assets/Icons/Email.svg';
import { Link, useParams } from 'react-router-dom';

const Footer = () => {
  const { t, i18n } = useTranslation();
  const paragraphClass = i18n.language === 'ar' ? 'arabic-paragraph' : 'english-paragraph';
  const sectionClass = i18n.language === 'ar' ? 'arabic-end-section' : '';
  const { lang } = useParams();

    useEffect(() => {
        i18n.changeLanguage(lang);
    }, [lang, i18n]);

  return (
    <footer className={`footer ${i18n.language === 'ar' ? 'rtl ar-footer' : 'ltr'}`}>
      <div className="footer-content">
        <div className='wrapping-footer-logo-mob'>
          <div className={`left-column ${i18n.language === 'ar' ? 'ar-left-column' : ''}`}>
            <div className="logo">
              <img src={logo} alt="Logo" />
            </div>
            <p className={`${i18n.language === 'ar' ? 'arsemi' : 'enregular'} ${paragraphClass}`}>{t("footer-text")}</p>
            <div className={`${i18n.language === 'ar' ? 'arsocial-icons' : 'asocial-icons'}`}>
              <ul className={`social-icons ${i18n.language === 'ar' ? 'arsi' : 'si'}`}>
                <li className="social-icon insta">
                  <a href={config.instagramUrl} target="_blank" rel="noopener noreferrer">
                    <img src={insta} alt="Instagram Icon" width={17} height={17} />
                  </a>
                </li>
                <li className="social-icon twitter">
                  <a href={config.twitterUrl} target="_blank" rel="noopener noreferrer">
                    <img src={twitter} alt="Twitter Icon" width={17} height={17} />
                  </a>
                </li>
                <li className="social-icon linked">
                  <a href={config.linkedinUrl} target="_blank" rel="noopener noreferrer">
                    <img src={linkedin} alt="Linkedin Icon" width={17} height={17} />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className='wrapping-footer-mob'>
          <div className={`middle-column ${i18n.language === 'ar' ? 'ar-middle-column rtl' : 'ltr'}`}>
            <div className="office">
              <h3 className={`${i18n.language === 'ar' ? 'arbold' : 'enheavy'} ${paragraphClass}`}>{t("footer-first-title")}</h3>
              <div className={` ${i18n.language === 'ar' ? 'rtl' : 'ltr'}`}>
                <a href="https://www.google.com/maps/place/United+Arab+Emirates/@24.0333337,54.3666678,12z/data=!4m2!4m1!3e0" target='_blank'>
                  <div className="icon-text">
                    <div className="icon">
                      <img src={location} alt="Icon 1" />
                    </div>
                    <p className={`${i18n.language === 'ar' ? 'arregular' : 'enregular'} ${paragraphClass}`}>
                      {t("footer-first-subtitle1")}
                    </p>
                  </div>
                </a>
                <a href="mailto:support@hani.app" target='_blank' className='mail-anchor'>
                  <div className="icon-text">
                    <div className="icon">
                      <img src={mail} alt="Icon 2" />
                    </div>
                    <p className={`${i18n.language === 'ar' ? 'arregular' : 'enregular'} ${paragraphClass}`}>
                      {t("footer-second-subtitle1")}
                    </p>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className={`right-column ${sectionClass}`}>
            <div className="get-app">
              <h3 className={`${i18n.language === 'ar' ? 'arbold' : 'enheavy'} ${paragraphClass}`}>{t("footer-second-title")}</h3>
              <a href={`${config.baseUrl}/qr/download`} target='_blank'>
                <div>
                  <p className={`${i18n.language === 'ar' ? 'arregular' : 'enregular'} ${paragraphClass}`}>{t("footer-first-subtitle2")}</p>
                </div></a>
            </div>
          </div>
          <div className="right-column">
            <div className="help-center">
              <h3 className={`${i18n.language === 'ar' ? 'arbold' : 'enheavy'} ${paragraphClass}`}>{t("footer-third-title")}</h3>
              <Link to={`/${i18n.language}/terms`}>
                <p className={`${i18n.language === 'ar' ? 'arregular' : 'enregular'} ${paragraphClass}`}>{t("footer-first-subtitle3")}</p>
              </Link>
              <a href={`/${i18n.language}/privacy`}> <p className={`privacy ${i18n.language === 'ar' ? 'arregular' : 'enregular'} ${paragraphClass}`}>{t("footer-second-subtitle3")}</p></a>
            </div>
          </div>
        </div>
      </div>
      <div className={`footer-bottom ${i18n.language === 'ar' ? 'ar-footer-bottom' : ''}`}>
        <p className={`${i18n.language === 'ar' ? 'arregular' : 'enregular'} ${paragraphClass}`}>

          <span className={`${i18n.language === 'ar' ? 'arsemi ar-hani' : 'arsemi'}`}>{t("Hani")} <span>&nbsp; - &nbsp; </span> </span>
          <span>{t("footer-bottom")}</span>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
