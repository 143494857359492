import React, { useEffect } from 'react'
import Navbar from './Navbar'
import Footer from './Footer'
import { useTranslation } from 'react-i18next';

function Privacy() {
    const { t, i18n } = useTranslation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Navbar />
            <div className='py-14 privacy-section'>
                <div className={`${i18n.language === 'ar' ? 'article-ar' : 'article'}`}>
                    <h2>{t('ph1')}</h2>
                    <p>
                        {t('pp1')}
                    </p>
                </div>
                <div className={`${i18n.language === 'ar' ? 'article-ar' : 'article'}`}>
                    <h2>{t('ph2')}</h2>
                    <p>
                        {t('pp2')}
                    </p>
                    <p>
                        {t('pp3')}
                    </p>
                    <p>
                        {t('pp4')}
                    </p>
                    <p>
                        {t('pp5')}
                    </p>
                    <p>
                        {t('pp6')}
                    </p>
                    <p>
                        {t('pp7')}
                    </p>
                    <p>
                        {t('pp8')}
                    </p>
                    <p>
                        {t('pp9')}
                    </p>
                </div>
                <div className={`${i18n.language === 'ar' ? 'article-ar' : 'article'}`}>
                    <h2>{t('ph3')}</h2>
                    <p>
                        {t('pp10')}
                    </p>
                    <p>
                        {t('pp11')}
                    </p>
                    <p>
                        {t('pp12')}
                    </p>
                    <p>
                        {t('pp13')}
                    </p>
                    <p>
                        {t('pp14')}
                    </p>
                    <p>
                        {t('pp15')}
                    </p>
                    <p>
                        {t('pp16')}
                    </p>
                </div>
                <div className={`${i18n.language === 'ar' ? 'article-ar' : 'article'}`}>
                    <h2>{t('ph4')}</h2>
                    <ul>
                        <li>
                            <p className={`${i18n.language === 'ar' ? 'blue-p' : ''}`}>{t('ul1-p')}</p>
                            <ul>
                                <li>
                                    {t('pl1')}
                                </li>
                                <li>
                                    {t('pl2')}
                                </li>
                            </ul>
                        </li>
                        <li>
                            <p className={`${i18n.language === 'ar' ? 'blue-p' : ''}`}>{t('pl3')}</p>
                            <ul>
                                <li>
                                    {t('pl4')}
                                </li>
                            </ul>
                        </li>
                        <li>
                            <p className={`${i18n.language === 'ar' ? 'blue-p' : ''}`}>{t('pl5')}</p>
                            <ul>
                                <li>
                                    {t('pl6')}
                                </li>
                            </ul>
                        </li>
                        <li>
                            <p>{t('pl7')}</p>
                            <ul>
                                <li>
                                    {t('pl8')}
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div className={`${i18n.language === 'ar' ? 'article-ar' : 'article'}`}>
                    <h2>{t('ph5')}</h2>
                    <p>
                        {t('pp17')}
                    </p>
                </div>
                <div className={`${i18n.language === 'ar' ? 'article-ar' : 'article'}`}>
                    <h2>{t('ph6')}</h2>
                    <p>
                        {t('pp18')}
                    </p>
                </div>
                <div className={`${i18n.language === 'ar' ? 'article-ar' : 'article'}`}>
                    <h2>{t('ph7')}</h2>
                    <p>
                        {t('pp19')}
                    </p>
                    <p>
                        {t('pp20')}
                    </p>
                    <p>
                        {t('pp21')}
                    </p>
                    <p>
                        {t('pp22')}
                    </p>
                    <ul>
                        <li>
                            {t('pp23')}
                        </li>
                        <li>
                            {t('pp24')}
                        </li>
                        <li>
                            {t('pp25')}
                        </li>
                        <li>
                            {t('pp26')}
                        </li>
                        <li>
                            {t('pp27')}
                        </li>
                        <li>
                            {t('pp28')}
                        </li>
                        <li>
                            {t('pp29')}
                        </li>
                        <li>
                            {t('pp30')}
                        </li>
                        <li>
                            {t('pp31')}
                        </li>
                    </ul>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Privacy