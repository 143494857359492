import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Navbar from './Navbar';
import Header from './Header';
import Features from './Features';
import Pickup from './Pickup';
import Partner from './Partner';
import FAQ from './FAQ';
import PartnerForm from './PartnerForm';
import Footer from './Footer';
import { useParams } from 'react-router-dom';


const Home = () => {
  const { t, i18n } = useTranslation();
  const { lang } = useParams();

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang, i18n]);


  return (
    <>
      <div className={`App `}>
        <div className={` ${i18n.language === 'ar' ? 'ar-bgimg' : 'en-bgimg'}`}>
          <Navbar />
          <Header />
        </div>
        <br />
        <br />
        <br />
        <br />
        <Features />
        <br />
        <Pickup />
        <br />
        <Partner />
        <br />
        <FAQ />
        <br />
        <PartnerForm />
        <br />
        <Footer />
      </div>
    </>
  )
}

export default Home