import { useTranslation } from 'react-i18next';
import i18n from '../i18n';
import Order from '../Assets/Icons/Order.svg';
import Wallet from '../Assets/Icons/Wallet.svg';
import CashBack from '../Assets/Icons/Cash-Back.svg';

const Boxes = () => {
  const { t } = useTranslation();

  return (
    <div className={`box-section ${i18n.language === 'ar' ? 'rtl' : 'ltr'}`}>
      <div className={`  ${i18n.language === 'ar' ? 'ar-box-header ' : 'box-header '}`}>
        <h1 className={`${i18n.language === 'ar' ? 'arbold' : 'enbold enmob-med'}`}>{t('features-title')}</h1>
        <p className={`${i18n.language === 'ar' ? 'arregular' : 'enregular '}`}>{t('features-subtitle')}</p>
      </div>
      <div className="box-container">
        <div className="box">
          <img src={Order} alt="Box 1" />
          <div className="box-content">
            <h2 className={`${i18n.language === 'ar' ? 'arsemi' : 'enmedium'}`}>{t('feat1-title')}</h2>
            <p className={`${i18n.language === 'ar' ? 'arregular' : 'enregular'}`}>{t('feat1-sub')}</p>
          </div>
        </div>
        <div className="box">
          <img src={Wallet} alt="Box 1" />
          <div className="box-content">
            <h2 className={`${i18n.language === 'ar' ? 'arsemi' : 'enmedium'}`}>{t('feat2-title')}</h2>
            <p className={`${i18n.language === 'ar' ? 'arregular' : 'enregular'}`}>{t('feat2-sub')}</p>
          </div>
        </div>
        <div className="box">
          <img src={CashBack} alt="Box 1"/>
          <div className="box-content">
            <h2 className={`${i18n.language === 'ar' ? 'arsemi' : 'enmedium'}`}>{t('feat3-title')}</h2>
            <p className={`${i18n.language === 'ar' ? 'arregular' : 'enregular'}`}>{t('feat3-sub')}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Boxes;
