import { useTranslation } from 'react-i18next';
import i18n from '../i18n';
import join from "../Assets/Illusrations/Join us.svg"
import { useState } from 'react';
import { config } from '../config/index';
import { Loader } from './Loader';

type FormData = {
    shopName: string;
    phone: string;
    name: string;
    country: string;
    email: string;
    city: string;
    [key: string]: string;
};


const PartnerForm = () => {
    const { t } = useTranslation();
    const [formData, setFormData] = useState<FormData>({
        shopName: '',
        phone: '',
        name: '',
        country: '',
        email: '',
        city: ''
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [message, setMessage] = useState('');
    const requiredFields: Array<keyof FormData> = ['shopName', 'phone', 'name', 'country', 'city'];

    const validateForm = () => {
        const isValid = requiredFields.every(field => formData[field].trim() !== '');
        return isValid;
    };

    const handleChange = (e: { target: { name: any; value: any; }; }) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
        setError('');
    };

    const handleSubmit = async (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        
        if (!validateForm()) {
            setError('Please fill in all required fields.');
            return;
        }
        
        try {
            setLoading(true);
            const response = await fetch(`${config.baseUrl}/api/partners`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    contact: {
                        name: formData.name,
                        email: formData.email,
                        phone: formData.phone,
                    },
                    shop: {
                        name: formData.shopName,
                        country: formData.country,
                        city: formData.city,
                    },
                }),
            });


            if (response.ok) {
                setMessage('Your request has been sent successfully.');
                setError('');
            } else {
                const errorMessage = await response.text();
                setError(errorMessage || 'Failed to send the request.');
                setMessage('');
            }
            setLoading(false);
        } catch (error) {
            setError('An error occurred while sending your request, please try again');
            setMessage('');
            setLoading(false);
        }
    };


    return (
        <div className={`form-container ${i18n.language === 'ar' ? 'rtl' : 'ltr'}`} id='partner'>
            <div className={`form-form ${i18n.language === 'ar' ? 'ar-form-form' : ''}`}>
                <h2 className={`${i18n.language === 'ar' ? 'arbold ar-heading3' : 'enbold enmob-med'}`}>{t("form-title")}</h2>
                <h3 className={`${i18n.language === 'ar' ? 'arsemi ar-heading3' : 'enmedium'}`}>{t("form-subtitle")}</h3>
                <p className={`${i18n.language === 'ar' ? 'arregular' : 'enmedium enmob-reg'}`}>{t("form-description")}</p>
                <div className='error'>
                </div>
                <div className="input-grid">
                    <div className="input-group">
                        <label htmlFor="shopName" className={`${i18n.language === 'ar' ? 'arregular' : 'enmedium'}`}>{t("input1")}</label>
                        <input type="text" id="shopName" name="shopName" value={formData.shopName} onChange={handleChange} placeholder={t("placeholder1")} required />
                    </div>
                    <div className="input-group">
                        <label htmlFor="phone" className={`${i18n.language === 'ar' ? 'arregular' : 'enmedium'}`}>{t("input2")}</label>
                        <input type="tel" id="phone" name="phone" value={formData.phone} onChange={handleChange} placeholder={t("placeholder2")} required />
                    </div>
                    <div className="input-group">
                        <label htmlFor="name" className={`${i18n.language === 'ar' ? 'arregular' : 'enmedium'}`}>{t("input3")}</label>
                        <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} placeholder={t("placeholder3")} required />
                    </div>
                    <div className="input-group">
                        <label htmlFor="country" className={`${i18n.language === 'ar' ? 'arregular' : 'enmedium'}`}>{t("input4")}</label>
                        <input type="text" id="country" name="country" value={formData.country} onChange={handleChange} placeholder={t("placeholder4")} required />
                    </div>
                    <div className="input-group">
                        <label htmlFor="email" className={`${i18n.language === 'ar' ? 'arregular' : 'enmedium'}`}>{t("input5")}</label>
                        <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} placeholder={t("placeholder5")} required />
                    </div>
                    <div className="input-group">
                        <label htmlFor="city" className={`${i18n.language === 'ar' ? 'arregular' : 'enmedium'}`}>{t("input6")}</label>
                        <input type="text" id="city" name="city" value={formData.city} onChange={handleChange} placeholder={t("placeholder6")} required />
                    </div>
                </div>
                <div className="input-group">
                    <button className={`submit-button ${i18n.language === 'ar' ? 'arbold' : 'enbold enmob-med'}`}  style={{ display: loading? 'none' : 'inline' }}  onClick={handleSubmit}>{t("button")}</button>
                    <div style={{ alignSelf: 'center', marginTop: '10px' }}>
                        {error ? <span className='error-msg'>{error}</span> :
                            loading ? <Loader size={40} /> :
                                message ? <span className='success-msg'>{message}</span> : null}
                    </div>
                    <p className={`${i18n.language === 'ar' ? 'arsemi' : 'enregular'}`}>{t("btn-desc")} <a href="#">{t("login")}</a></p>
                </div>
            </div>

            <div className={`form-image ${i18n.language === 'ar' ? 'ar-form-image' : ''}`}>
                <img src={join} alt="Partner" className='image' />
                <div className='under-image'>
                    <p className={`${i18n.language === 'ar' ? 'arsemi' : 'enregular'}`}>{t("partner-quest")} <a href="mailto:sales@hani.app">sales@hani.app</a></p>
                </div>
            </div>
        </div>
    );
}

export default PartnerForm
