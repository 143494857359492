import React from 'react'
import Boxes from './Boxes'
import order from '../Assets/Illusrations/Pre-Order.svg';
import cash from '../Assets/Illusrations/Cash-Back.svg';
import dine from '../Assets/Illusrations/Dine-In.svg';
import data from '../Assets/Illusrations/Data Analytics.svg';
import { useTranslation } from 'react-i18next';
import i18n from '../i18n';
import '../App.css';


const Features = () => {
  const { t } = useTranslation();

  return (
    <div id='features'>
      <Boxes />
      <div className={`${i18n.language === 'ar' ? 'ar-features-container rtl' : 'features-container ltr'}`}>
        <div className='feature-center'>
          <div className="feature feat-between">
            <div className='center-imag'>
              <img src={order} alt="Feature 1" className="feature-image" width={250} height={250} />
            </div>
            <div className="feature-content">
              <h1 className={`${i18n.language === 'ar' ? 'arbold' : 'enheavy enmob-medium'}`}>{t('feature1-title')}</h1>
              <ul>
                <li className={`${i18n.language === 'ar' ? 'arregular armob-semi' : 'enregular'}`}>{t('feature1-list1')}</li>
                <li className={`${i18n.language === 'ar' ? 'arregular armob-semi' : 'enregular'}`}>{t('feature1-list2')}</li>
                <li className={`${i18n.language === 'ar' ? 'arregular armob-semi' : 'enregular'}`}>{t('feature1-list3')}</li>
              </ul>
              <hr className="feature-line" />
            </div>
          </div>
          <div className="feature reverse feat-around">
            <div className={`${i18n.language === 'ar' ? 'ar-center-image' : 'center-image'}`}>
              <img src={cash} alt="Feature 2" className="feature-image" width={250} height={250} />
            </div>
            <div className="feature-content">
              <h1 className={`${i18n.language === 'ar' ? 'arbold' : 'enheavy enmob-medium'}`}>{t('feature2-title')}</h1>
              <ul>
                <li className={`${i18n.language === 'ar' ? 'arregular armob-semi' : 'enregular'}`}>{t('feature2-list1')}</li>
                <li className={`${i18n.language === 'ar' ? 'arregular armob-semi' : 'enregular'}`}>{t('feature2-list2')}</li>
                <li className={`${i18n.language === 'ar' ? 'arregular armob-semi' : 'enregular'}`}>{t('feature2-list3')}</li>
                <li className={`${i18n.language === 'ar' ? 'arregular armob-semi' : 'enregular'}`}>{t('feature2-list4')}</li>
              </ul>
              <hr className="feature-line" />
            </div>
          </div>
          <div className="feature feature3 feat-between">
            <div className='center-imag'>
              <img src={dine} alt="Feature 3" className="feature-image" width={250} height={250} />
            </div>
            <div className="feature-content">
              <h1 className={`${i18n.language === 'ar' ? 'arbold' : 'enheavy enmob-medium'}`}>{t('feature3-title')}</h1>
              <ul className='content3'>
                <li className={`${i18n.language === 'ar' ? 'arregular armob-semi' : 'enregular'}`}>{t('feature3-list1')}</li>
                <li className={`${i18n.language === 'ar' ? 'arregular armob-semi' : 'enregular lineheight3'}`}>{t('feature3-list2')}</li>
              </ul>
              <hr className="feature-line" />
            </div>
          </div>
          <div className="feature reverse feat-around">
            <div className={`${i18n.language === 'ar' ? 'ar-center-image' : 'center-image'}`}>
              <img src={data} alt="Feature 4" className="feature-image" width={250} height={250} />
            </div>
            <div className="feature-content">
              <h1 className={`${i18n.language === 'ar' ? 'arbold' : 'enheavy enmob-medium'}`}>{t('feature4-title')}</h1>
              <ul>
                <li className={`${i18n.language === 'ar' ? 'arregular armob-semi' : 'enregular'}`}>{t('feature4-list1')}</li>
                <li className={`${i18n.language === 'ar' ? 'arregular armob-semi' : 'enregular'}`}>{t('feature4-list2')}</li>
                <li className={`${i18n.language === 'ar' ? 'arregular armob-semi' : 'enregular'}`}>{t('feature4-list3')}</li>
              </ul>
              <hr className="feature-line" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Features
