import React from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '../i18n';
import mobile from '../Assets/Illusrations/mobile.svg';
import armobile from '../Assets/Illusrations/mobilear.svg';
import insta from '../Assets/Icons/instagrem.svg';
import linkedin from '../Assets/Icons/Linkedin.svg';
import twitter from '../Assets/Icons/Twitter.svg';
// import play from '../Assets/Icons/Play.svg';
// import playar from '../Assets/Icons/Playar.svg';
import { config } from '../config/index';

function Header() {
  const { t, i18n } = useTranslation();
  const mobileImage = i18n.language === 'ar' ? armobile : mobile;
  const textParts = t('welcome').split('\n');
  // const playIcon = i18n.language === 'ar' ? playar : play;

  return (
    <header className={` header ${i18n.language === 'ar' ? 'rtl' : 'ltr'}`}>
      <div className="header-content">
        <div className="header-left">
          <div className='underline'>
            <p className={`blue-welcome ${i18n.language === 'ar' ? 'arbold' : 'enbold'}`}>
              <span className={`underline-textt  ${i18n.language === 'ar' ? 'arrevolutionizing' : 'enrevolutionizing'}`}>
                {textParts[0]}
              </span><br />
              <span className={` rest-of-text ${i18n.language === 'ar' ? 'arunderline-text' : 'enunderline-text'}`}>
                {textParts[1]}
              </span>
            </p>
          </div>
          <div className={`btn-play ${i18n.language === 'ar' ? 'btn-play-ar' : 'btn-play-en'}`}>
            <div>
              <button className={`download-button ${i18n.language === 'ar' ? 'arbold' : 'enheavy'}`}>{t('download')}</button>
            </div>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <div className='watchi'>
              {/* <p className={` watch ${i18n.language === 'ar' ? 'arbold' : 'enbold'}`}>{t('watch')}
                &nbsp;&nbsp;
                <span className='ply-icon'>
                  &nbsp;
                  <img src={playIcon} alt="Play Icon" width={9} height={9} />
                </span> 
              </p>*/}
            </div>
          </div>
          <br />
          <div className={`${i18n.language === 'ar' ? 'arsocial-icons' : 'asocial-icons'}`}>
            <ul className={`social-icons ${i18n.language === 'ar' ? 'arsi' : 'si'}`}>
              <li className="social-icon insta">
                <a href={config.instagramUrl} target="_blank" rel="noopener noreferrer">
                  <img src={insta} alt="Instagram Icon" width={17} height={17} />
                </a>
              </li>
              <li className="social-icon twitter">
                <a href={config.twitterUrl} target="_blank" rel="noopener noreferrer">
                  <img src={twitter} alt="Twitter Icon" width={17} height={17} />
                </a>
              </li>
              <li className="social-icon linked">
                <a href={config.linkedinUrl} target="_blank" rel="noopener noreferrer">
                  <img src={linkedin} alt="Linkedin Icon" width={17} height={17} />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className={`${i18n.language === 'ar' ? 'header-right-ar' : 'header-right'}`}>
          <img src={mobileImage} alt="Mobile Image" className='image-mobile' />
        </div>
      </div>
    </header>
  );
}

export default Header;
