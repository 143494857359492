import { useTranslation } from 'react-i18next';
import i18n from '../i18n';
import '../App.css';

const Pickup = () => {
  const { t } = useTranslation();

  return (
    <div className={`pickup-section ${i18n.language === 'ar' ? 'rtl' : 'ltr'}`}>
      <div className="pickup-center">
        <div className={`  ${i18n.language === 'ar' ? 'ar-pickup-header ' : 'pickup-header '}`}>
          <h1 className={`${i18n.language === 'ar' ? 'arbold' : 'enbold enmob-med'}`}>{t('pickup-title')}</h1>
          <p className={`${i18n.language === 'ar' ? 'arregular' : 'enregular '}`}>{t('pickup-subtitle')}</p>
        </div>
        <div className="pickup-container">
          <div className={` ${i18n.language === 'ar' ? 'ar-pickup-content' : 'pickup-content'}`}>
            <h2 className={`${i18n.language === 'ar' ? 'enbold ar-nb1' : 'enbold en-nb1'}`}>{t('pick-title1')}</h2>
            <p className={`${i18n.language === 'ar' ? 'arregular' : 'enmedium'}`}>{t('pick-subtitle1')}</p>
            <div className="container-hr">
              <hr className="mobile-hr" />
            </div>
          </div>
          <div className={` ${i18n.language === 'ar' ? 'ar-pickup-content' : 'pickup-content'}`}>
            <h2 className={`${i18n.language === 'ar' ? 'enbold ar-nb2' : 'enbold en-nb2'}`}>{t('pick-title2')}</h2>
            <p className={`${i18n.language === 'ar' ? 'arregular' : 'enmedium'}`}>{t('pick-subtitle2')}</p>
            <div className="container-hr">
              <hr className="mobile-hr" />
            </div>
          </div>
          <div className={` ${i18n.language === 'ar' ? 'ar-pickup-content ar-pickup-content3' : 'pickup-content pickup-content3'}`}>
            <h2 className={`${i18n.language === 'ar' ? 'enbold ar-nb3' : 'enbold en-nb3'}`}>{t('pick-title3')}</h2>
            <p className={`${i18n.language === 'ar' ? 'arregular' : 'enmedium'}`}>{t('pick-subtitle3')}</p>
            <div className="container-hr">
              <hr className="mobile-hr" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pickup;
