import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Home from '../components/Home';
import Terms from '../components/Terms';
import Privacy from '../components/Privacy';

export const AppRouter = () => {
  

  return (
    <Router>
        <Routes>
          <Route path="/:lang" element={<Home />} />
          <Route path="/:lang/terms" element={<Terms />} />
          <Route path="/:lang/privacy" element={<Privacy />} />
          <Route path="*" element={<Navigate to="/en" />} />
        </Routes>
      </Router>
  );
}
