import { useTranslation } from 'react-i18next';
import i18n from '../i18n';
import '../App.css';
import fresh from '../Assets/partner/fresh.svg';
import kakaa from '../Assets/partner/kakaa.svg';
import kazumi from '../Assets/partner/kazumi.svg';
import mazah from '../Assets/partner/mazah.svg';
import wood from '../Assets/partner/wood.svg';
import wow from '../Assets/partner/wow.svg';

const Partner = () => {
    const { t } = useTranslation();

    return (
        <div className={`partner-section`}>
            <div className={`  ${i18n.language === 'ar' ? 'ar-partner-header rtl' : 'partner-header ltr'}`}>
                <h1 className={`${i18n.language === 'ar' ? 'arbold' : 'enbold enmob-med'}`}>{t('partner-title')}</h1>
                <p className={`${i18n.language === 'ar' ? 'arregular' : 'enregular '}`}>{t('partner-subtitle')}</p>
            </div>
            <div className="change">
                <div className={`${i18n.language === 'ar' ? 'ar-partner-container' : 'partner-container'}`}>
                    <div className={`${i18n.language === 'ar' ? 'ar-partner-image' : 'partner-image'}`}>
                        <img src={fresh} alt="Image 1" />
                    </div>
                    <div className={`${i18n.language === 'ar' ? 'ar-partner-image' : 'partner-image'}`}>
                        <img src={kakaa} alt="Image 2" />
                    </div>
                    <div className={`${i18n.language === 'ar' ? 'ar-partner-image' : 'partner-image'}`}>
                        <img src={kazumi} alt="Image 3" />
                    </div>
                    <div className={`${i18n.language === 'ar' ? 'ar-partner-image' : 'partner-image'}`}>
                        <img src={mazah} alt="Image 4" />
                    </div>
                    <div className={`${i18n.language === 'ar' ? 'ar-partner-image' : 'partner-image'}`}>
                        <img src={wood} alt="Image 5" />
                    </div>
                    <div className={`${i18n.language === 'ar' ? 'ar-partner-image' : 'partner-image'}`}>
                        <img src={wow} alt="Image 6" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Partner;
