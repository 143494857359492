import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '../i18n';

const FAQ = () => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState([false, false, false]);

    const toggleQuestion = (index: number) => {
        const updatedIsOpen = [...isOpen];
        updatedIsOpen[index] = !updatedIsOpen[index];
        setIsOpen(updatedIsOpen);
    };

    return (
        <div className={`faq-section ${i18n.language === 'ar' ? 'rtl' : 'ltr'}`} id='faqs'>
            <div className={`faq-header ${i18n.language === 'ar' ? 'ar-faq-header' : ''}`}>
                <h1 className={`${i18n.language === 'ar' ? 'arbold' : 'enbold enmob-med'}`}>{t('faq-title')}</h1>
                <p className={`${i18n.language === 'ar' ? 'arregular' : 'enregular'}`}>{t('faq-subtitle')}</p>
            </div>
            <div className="faq-questions">
                <div className="faq-question">
                    <div
                        className={`faq-question-title ${isOpen[0] ? 'open' : ''} 
                        ${i18n.language === 'ar' ? 'arsemi' : 'enmedium'}`}
                        onClick={() => toggleQuestion(0)}
                    >
                        {t('faq-question1')}
                        <div className={`arrow-icon ${isOpen[0] ? 'up' : 'down'} ${i18n.language === 'ar' ? 'ar-arrow-icon' : ''}`} />
                    </div>
                    {isOpen[0] && (
                        <div className={`faq-answer ${i18n.language === 'ar' ? 'arregular' : 'enregular'}`}>{t('faq-answer1')}</div>
                    )}
                </div>
                <div className="faq-question">
                    <div
                        className={`faq-question-title ${isOpen[1] ? 'open' : ''} 
                        ${i18n.language === 'ar' ? 'arsemi' : 'enmedium'}`}
                        onClick={() => toggleQuestion(1)}
                    >
                        {t('faq-question2')}
                        <div className={`arrow-icon ${isOpen[1] ? 'up' : 'down'} ${i18n.language === 'ar' ? 'ar-arrow-icon' : ''}`} />
                    </div>
                    {isOpen[1] && (
                        <div className={`faq-answer ${i18n.language === 'ar' ? 'arregular' : 'enregular'}`}>{t('faq-answer2')}</div>
                    )}
                </div>
                <div className="faq-question">
                    <div
                        className={`faq-question-title ${isOpen[2] ? 'open' : ''} 
                        ${i18n.language === 'ar' ? 'arsemi' : 'enmedium'}`}
                        onClick={() => toggleQuestion(2)}
                    >
                        {t('faq-question3')}
                        <div className={`arrow-icon ${isOpen[2] ? 'up' : 'down'} ${i18n.language === 'ar' ? 'ar-arrow-icon' : ''}`} />
                    </div>
                    {isOpen[2] && (
                        <div className={`faq-answer ${i18n.language === 'ar' ? 'arregular' : 'enregular'}`}>{t('faq-answer3')}</div>
                    )}
                </div>
            </div>
            <div className="faq-bottom">
                <h2 className={`${i18n.language === 'ar' ? 'arregular bottom' : 'enregular'}`}>{t("faq-bottom")}</h2>
                <p className={`${i18n.language === 'ar' ? 'arregular' : 'enregular'}`}>{t("faq-bottom-email")}
                    <a href='mailto:support@hani.app' className={`${i18n.language === 'ar' ? 'enmedium' : 'enmedium'}`}> support@hani.app</a>
                </p>
            </div>
        </div>
    );
};

export default FAQ;
