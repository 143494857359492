import React, { useEffect } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Navbar from './Navbar';
import Footer from './Footer';
import { useTranslation } from 'react-i18next';

function Terms() {
    const { t, i18n } = useTranslation();
    const { lang } = useParams();

    useEffect(() => {
        i18n.changeLanguage(lang);
        window.scrollTo(0, 0);
    }, [lang, i18n]);

    return (
        <>
            <Navbar />
            <div className={`py-14 privacy-section ${i18n.language === 'ar' ? 'rtl' : 'ltr'}`}>
                <div className={`${i18n.language === 'ar' ? 'article-ar' : 'article'}`}>
                    <h2>{t('terms')}</h2>
                    <p>
                        <ul>
                            <li>{t('ul1-l1')}</li>
                            <li>{t('ul1-l2')}</li>
                            <li>{t('ul1-l3')}</li>
                            <li>
                                {t('ul1-l4')}
                            </li>
                        </ul>
                    </p>
                </div>
                <div className={`${i18n.language === 'ar' ? 'article-ar' : 'article'}`}>
                    <h2>{t('h1')}</h2>
                    <p>
                        {t('p1')}
                    </p>
                </div>
                <div className={`${i18n.language === 'ar' ? 'article-ar' : 'article'}`}>
                    <h2>{t('h2')}</h2>
                    <p>
                        {i18n.language === 'ar' && (
                            <>
                                1-
                            </>)}
                    </p>
                    <p>
                        {t('p2')}
                    </p>
                    <p>
                        {t('p3')}
                    </p>
                    <p>
                        {t('p4')}
                    </p>
                    <p>
                        {t('p5')}
                    </p>
                    <p>
                        {t('p6')}
                    </p>
                    <p>
                        {t('p7')}
                    </p>
                    <p>
                        {t('p8')}
                    </p>
                    <p>
                        {t('p9')}
                    </p>
                    <p>
                        {t('p10')}
                    </p>
                    <p>
                        {t('p11')}
                    </p>
                    <p>
                        {i18n.language === 'ar' && (
                            <>
                                2-
                            </>)}
                    </p>
                    <p>
                        {t('p12')}
                    </p>
                </div>
                <div className={`${i18n.language === 'ar' ? 'article-ar' : 'article'}`}>
                    <h2>{t('h3')}</h2>
                    <p>
                        {t('p13')}
                    </p>
                </div>
                <div className={`${i18n.language === 'ar' ? 'article-ar' : 'article'}`}>
                    <h2>{t('h4')}</h2>
                    <p>
                        {t('p14')}
                    </p>
                </div>
                <div className={`${i18n.language === 'ar' ? 'article-ar' : 'article'}`}>
                    <h2>{t('h5')}</h2>
                    <p>
                        {t('p15')}
                    </p>
                </div>
                <div className={`${i18n.language === 'ar' ? 'article-ar' : 'article'}`}>
                    <h2>{t('h6')}</h2>
                    <p style={{
                        paddingBottom: i18n.language === 'ar' ? '' : '2.5rem',
                    }}>
                        {t('p16')}
                    </p>
                    <p style={{
                        paddingBottom: i18n.language === 'ar' ? '' : '2.5rem',
                    }}>
                        {t('p17')}
                    </p>
                    <p style={{
                        paddingBottom: i18n.language === 'ar' ? '' : '2.5rem',
                    }}>
                        {t('p18')}
                    </p>
                    <p style={{
                        paddingBottom: i18n.language === 'ar' ? '' : '2.5rem',
                    }}>
                        {t('p19')}
                    </p>
                    <p style={{ paddingBottom: i18n.language === 'ar' ? '' : '2.5rem' }}>
                        {t('p20')}
                    </p>
                    <p style={{ paddingBottom: i18n.language === 'ar' ? '' : '2.5rem' }}>
                        {t('p21')}
                    </p>
                    <p >
                        {t('p22')}
                    </p>
                </div>
                <div className={`${i18n.language === 'ar' ? 'article-ar' : 'article'}`}>
                    <h2>{t('h8')}</h2>
                    <p>
                        {t('p23')}
                    </p>
                </div>
                <div className={`${i18n.language === 'ar' ? 'article-ar' : 'article'}`}>
                    <h2>{t('h9')}</h2>
                    <p>
                        {t('p24')}
                    </p>
                </div>
                <div className={`${i18n.language === 'ar' ? 'article-ar' : 'article'}`}>
                    <h2>{t('h10')}</h2>
                    <p>
                        {t('p25')}
                    </p>
                </div>
                <div className={`${i18n.language === 'ar' ? 'article-ar' : 'article'}`}>
                    <h2>{t('h11')}</h2>
                    <p className={`${i18n.language === 'ar' ? 'text-[#00121F] text-left' : ''}`}
                        style={{
                            textAlign: i18n.language === 'ar' ? 'left' : 'left',
                        }}>
                        {t('p26')}
                    </p>
                </div>
                <div className={`${i18n.language === 'ar' ? 'article-ar' : 'article'}`}>
                    <h2>{t('h12')}</h2>
                    <p>
                        {t('p27')}
                    </p>
                </div>
                <div className={`${i18n.language === 'ar' ? 'article-ar' : 'article'}`}>
                    <h2>{t('h13')}</h2>
                    <p>
                        {t('p28')}
                    </p>
                </div>
                <div className={`${i18n.language === 'ar' ? 'article-ar' : 'article'}`}>
                    <h2>{t('h14')}</h2>
                    <p>
                        {t('p29')}
                    </p>
                </div>
                <div className={`${i18n.language === 'ar' ? 'article-ar' : 'article'}`}>
                    <h2>{t('h15')}</h2>
                    <p>
                        {t('p30')}
                    </p>
                </div>
                <div className={`${i18n.language === 'ar' ? 'article-ar' : 'article'}`}>
                    <h2>{t('h16')}</h2>
                    <p>
                        {t('p31')}
                    </p>
                </div>
                <div className={`${i18n.language === 'ar' ? 'article-ar' : 'article'}`}>
                    <h2>{t('h17')}</h2>
                    <p>
                        {t('p32')}
                    </p>
                </div>
                <div className={`${i18n.language === 'ar' ? 'article-ar' : 'article'}`}>
                    <h2>{t('h18')}</h2>
                    <p>
                        {t('p33')}
                    </p>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Terms