import { ReactComponent as LoaderSvg } from '../Assets/Icons/loader.svg';

interface propTypes {
  color?: string;
  size?: number;
  wrapperClass?: string;
  absolute?: boolean;
}

export const Loader = ({
  size = 100,
  color = '#004561',
  wrapperClass = '',
  absolute = false

}: propTypes) => {

  return (
    <div>
      <LoaderSvg fill={color} width={`${size}px`} height={`${size}px` } style={{background: 'none'}} />
    </div>
  )
}
