import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import logo from '../Assets/Hani Logo.svg';
import menuIcon from '../Assets/Icons/Menu.svg';
import symbol from '../Assets/Icons/Symbol.svg';
import { LANGUAGE_KEY } from '../i18n';
import { useNavigate } from 'react-router-dom';
import { config } from '../config/index';
import { t } from 'i18next';

interface MobileMenuLinksProps {
  i18nLanguage: string;
  scrollToSection: (sectionId: string) => void;
  switchLanguage: () => void;
  loginRedirect: () => void;
}

interface DesktopMenuLinksProps {
  i18nLanguage: string;
  scrollToSection: (sectionId: string) => void;
  switchLanguage: () => void;
  loginRedirect: () => void;
}

function Navbar() {
  const { t, i18n } = useTranslation();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const navigate = useNavigate();

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setMobileMenuOpen(false);
  };

  const scrollToSection = (sectionId: string) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
      closeMobileMenu();
    }
  };

  const switchLanguage = () => {
    const currentLanguage = i18n.language;
    const newLanguage = currentLanguage === 'ar' ? 'en' : 'ar';
    const newPath = window.location.pathname.replace(`/${currentLanguage}`, `/${newLanguage}`);
    navigate(newPath);
    i18n.changeLanguage(newLanguage);
    localStorage.setItem(LANGUAGE_KEY, newLanguage);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 1080) {
        setIsSmallScreen(true);
        setMobileMenuOpen(false);
      } else {
        setIsSmallScreen(false);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const navigateToHome = () => {
    navigate(`/${i18n.language}`);
  };

  const loginRedirect = () => {
    window.location.assign(`${config.baseUrl}/portal/login`);
  };

  return (
    <nav className={`header-navigation ${mobileMenuOpen ? 'menu-open' : ''}`}>
      <div className="header-top">
        <div className="logo" onClick={navigateToHome}>
          <img src={logo} alt="Logo" />
        </div>
        {isSmallScreen && (
          <div className="menu-icon" onClick={toggleMobileMenu}>
            {mobileMenuOpen ? (
              <img src={symbol} onClick={closeMobileMenu} alt="Symbol"  width={50} height={50}/>
            ) : (
              <img src={menuIcon} alt="Menu" width={50} height={50} />
            )}
          </div>
        )}
      </div>
      {isSmallScreen && mobileMenuOpen && (
        <MobileMenuLinks
          i18nLanguage={i18n.language}
          scrollToSection={scrollToSection}
          switchLanguage={switchLanguage}
          loginRedirect={loginRedirect}
        />
      )}
      {!isSmallScreen && (
        <DesktopMenuLinks
          i18nLanguage={i18n.language}
          scrollToSection={scrollToSection}
          switchLanguage={switchLanguage}
          loginRedirect={loginRedirect}
        />
      )}
    </nav>
  );
}

const MobileMenuLinks = ({ i18nLanguage, scrollToSection, switchLanguage, loginRedirect }: MobileMenuLinksProps) => {
  return (
    <div className={`mobile-links ${i18nLanguage === 'ar' ? 'rtl' : 'ltr'}`}>
      <ul>
        <li><a href={`/${i18nLanguage}/#features`} className={`other-link ${i18nLanguage === 'ar' ? 'arregular armob-semi' : 'enregular'}`} onClick={() => scrollToSection('features')}>{t('features')}</a></li>
        <li><a href={`/${i18nLanguage}/#faqs`} className={`other-link ${i18nLanguage === 'ar' ? 'arregular armob-semi' : 'enregular'}`} onClick={() => scrollToSection('faqs')}>{t('FAQs')}</a></li>
        <li><a href={`/${i18nLanguage}/#partner`} className={`other-link ${i18nLanguage === 'ar' ? 'arregular armob-semi' : 'enregular'}`} onClick={() => scrollToSection('partner')}>{t('Become a partner')}</a></li>
        <li><a onClick={switchLanguage} className={`other-link ${i18nLanguage === 'ar' ? 'arregular armob-semi' : 'enregular'}`}>{t('ar')}</a></li>
        <li>
          <button onClick={loginRedirect} className={`${i18nLanguage === 'ar' ? 'login-button-ar arbold login-button-medium' : 'login-button enmedium login-button-medium'}`}>{t('Login')}
          
          </button>
        </li>
      </ul>
    </div>
  );
};

const DesktopMenuLinks = ({ i18nLanguage, scrollToSection, switchLanguage, loginRedirect }: DesktopMenuLinksProps) => {
  return (
    <div className={`links ${i18nLanguage === 'ar' ? 'rtl' : 'ltr'}`}>
      <ul>
        <li><a href={`/${i18nLanguage}/`} className={`home-link ${i18nLanguage === 'ar' ? 'arbold' : 'enbold'}`} >{t('home')}</a></li>
        <li><a href={`/${i18nLanguage}/#features`} className={`other-link ${i18nLanguage === 'ar' ? 'arregular' : 'enregular'}`} onClick={() => scrollToSection('features')}>{t('features')}</a></li>
        <li><a href={`/${i18nLanguage}/#faqs`} className={`other-link ${i18nLanguage === 'ar' ? 'arregular' : 'enregular'}`} onClick={() => scrollToSection('faqs')}>{t('FAQs')}</a></li>
        <li><a href={`/${i18nLanguage}/#partner`} className={`other-link ${i18nLanguage === 'ar' ? 'arregular' : 'enregular'}`} onClick={() => scrollToSection('partner')}>{t('Become a partner')}</a></li>
        <li><a onClick={switchLanguage} className={`other-link ${i18nLanguage === 'ar' ? 'arregular' : 'enregular'}`}>{t('ar')}</a></li>
        <li><button onClick={loginRedirect} className={`${i18nLanguage === 'ar' ? 'login-button-ar arbold' : 'login-button enmedium'}`} >
          {t('Login')}
        </button>
        </li>
      </ul>
    </div>
  );
};

export default Navbar;
